import {useState, useMemo, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {useRouter} from 'next/router'
import {CSSTransition} from 'react-transition-group'
import {get} from '@cullylarson/f'
import {getYear} from '@@client/lib/dates'
import {preventDefault} from '@@client/lib/events'
import {useUpdate} from '@@client/lib/hooks'
import {routeToTop} from '@@client/lib/nextjs'
import FormSelect from '@@client/components/FormSelect'
import {buildSearchQuery} from './parts-helper'
import loadPartTypeNameList from './part-type-name/loadPartTypeNameList'
import loadMakeListDistinct from './make/loadMakeListDistinct'
import loadCarlineList from './carline/loadCarlineList'
import loadApplicationList from './application/loadApplicationList'

const haveApplicationOption = (application, entities) => entities.some(x => x.hollanderNumber === application)
const haveCarlineOption = (carlineName, entities) => entities.some(x => x.carlineName === carlineName)

const buildYearOptions = (min) => {
    const max = getYear()

    const options = []

    for(let y = max; y >= min; y--) {
        options.push({value: String(y), label: String(y)})
    }

    return options
}

const yearOptions = buildYearOptions(1940)

export function getHaveCategoryCodes(partTypeSlug) {
    // TODO -- store this in the API instead of hard-coding
    return ['engine-assembly'].includes(partTypeSlug)
}

export function CategoryCodeSelect({value, title, onChange, errors, name, idPrefix, id, partTypeSlug}) {
    // TODO -- fetch these from the API instead of hard-coding
    const categoryCodeOptions = useMemo(() => {
        if(partTypeSlug === 'engine-assembly') {
            return [
                {value: '', label: 'All'},
                {value: 'R', label: 'Remanufactured'},
            ]
        }
        else {
            return []
        }
    }, [partTypeSlug])

    return (
        <FormSelect
            idPrefix={idPrefix}
            id={id}
            name={name}
            title={title}
            placeholder='Select Category...'
            value={value}
            options={categoryCodeOptions}
            noOptionsPlaceholder='NO CATEGORIES'
            noOptionsMessage='No categories'
            onChange={onChange}
            errors={errors}
        />
    )
}

const mapStateToProps = ({parts: {partTypeName, make, carline, application}}) => ({
    partTypeNameStore: partTypeName,
    makeStore: make,
    carlineStore: carline,
    applicationStore: application,
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(({
    partTypeNameStore,
    makeStore,
    carlineStore,
    applicationStore,
    action = '/parts',
    disabled = false,
    buttonTitle = 'Find Parts',
    idPrefix = 'part-search',
    scrollOptionsIntoView = false,
    maxOptionsHeight = 200,
    paramErrors = {},
    onChange = () => {},
    onSubmit = () => {},
    doClearSearch = () => {}, // call this function with a function that will clear the search form
    ...restProps
}) => {
    const router = useRouter()

    const bottomRef = useRef(null)

    const externalCategoryCode = get('categoryCode', '', restProps)

    const [partTypeSlug, setPartTypeSlug] = useState(get('partTypeSlug', '', restProps))
    const [year, setYear] = useState(String(get('year', '', restProps)))
    const [make, setMake] = useState(get('make', '', restProps))
    const [carline, setCarline] = useState(get('carline', '', restProps))
    const [lastSelectedCarline, setLastSelectedCarline] = useState(get('carline', '', restProps))
    const [application, setApplication] = useState(get('application', '', restProps))
    const [categoryCode, setCategoryCode] = useState(externalCategoryCode)
    const [lastSelectedApplication, setLastSelectedApplication] = useState(get('application', '', restProps))

    const haveCategoryCodes = getHaveCategoryCodes(partTypeSlug)

    const handleParam = setValue => e => (e && e.target) ? setValue(e.target.value) : setValue(e)

    const handleApplicationChange = application => {
        setApplication(application)
        setLastSelectedApplication(application)
    }

    const handleCategoryCodeChange = categoryCode => {
        setCategoryCode(categoryCode)
    }

    const handleCarlineChange = carline => {
        setCarline(carline)
        setLastSelectedCarline(carline)
    }

    useUpdate(([partTypeSlug, year, make, carline, application, categoryCode]) => {
        onChange({partTypeSlug, year, make, carline, application, categoryCode})
    }, [partTypeSlug, year, make, carline, application, categoryCode])

    doClearSearch(() => {
        setPartTypeSlug('')
        setYear('')
        setMake('')
        setCarline('')
        setLastSelectedCarline('')
        setApplication('')
        setLastSelectedApplication('')
        setCategoryCode('')
    })

    const handleSubmit = preventDefault(() => {
        const query = buildSearchQuery({partTypeSlug, year, make, carlineName: carline, application, categoryCode})

        onSubmit(query)

        routeToTop(router, {
            pathname: action,
            query,
        })
    })

    const partTypeOptions = useMemo(() => {
        return partTypeNameStore.list.doing
            ? [{value: '', label: 'Loading...'}]
            : partTypeNameStore.list.entities.map(x => ({value: x.slug, label: x.name}))
    }, [partTypeNameStore.list.doing])

    const makeOptions = useMemo(() => {
        return makeStore.listDistinct.doing
            ? [{value: '', label: 'Loading...'}]
            : makeStore.listDistinct.entities.map(x => ({value: x.makeName, label: x.makeName}))
    }, [makeStore.listDistinct.doing])

    const carlineOptions = useMemo(() => {
        return carlineStore.list.doing
            ? [{value: '', label: 'Loading...'}]
            : carlineStore.list.entities.map(x => ({value: x.carlineName, label: x.carlineName}))
    }, [carlineStore.list.doing])

    const haveApplications = Boolean(applicationStore.list.entities.length)

    const applicationOptions = useMemo(() => {
        return applicationStore.list.doing
            ? [{value: '', label: 'Loading...'}]
            : applicationStore.list.entities.map(x => ({value: x.hollanderNumber, label: x.label}))
    }, [applicationStore.list.doing])

    // need to clear the application when we don't have any options (or else it will stick around, set in
    // the background, and show up in the query on submit). however, we don't want to clear it on initial
    // page load. simple solution: clear if no options, but then select the originally provided option if
    // it's available.
    useEffect(() => {
        const entities = applicationStore.list.entities

        if(!entities.length) {
            setApplication(null)
        }
        else if(haveApplicationOption(lastSelectedApplication, entities)) {
            setApplication(lastSelectedApplication)
        }
        else {
            setApplication(null)
        }
    }, [applicationStore.list.entities])

    // need to clear the carline when we don't have any options (or else it will stick around, set in
    // the background, and show up in the query on submit). however, we don't want to clear it on initial
    // page load. simple solution: clear if no options, but then select the originally provided option if
    // it's available.
    useEffect(() => {
        const entities = carlineStore.list.entities

        if(!entities.length) {
            setCarline(null)
        }
        else if(haveCarlineOption(lastSelectedCarline, entities)) {
            setCarline(lastSelectedCarline)
        }
        else {
            setCarline(null)
        }
    }, [carlineStore.list.entities])

    useEffect(() => {
        setCategoryCode(externalCategoryCode)
    }, [externalCategoryCode])

    // The part type could change such that we no longer want to show a category code. If that happens, we want to clear the category code (otherwise it'll stick around in searches)
    useEffect(() => {
        if(!haveCategoryCodes) {
            setCategoryCode('')
        }
    }, [haveCategoryCodes])

    const handleMenuOpen = () => {
        if(!scrollOptionsIntoView) return

        // need to wait a bit for the menu to actually open
        setTimeout(() => {
            bottomRef.current && bottomRef.current.scrollIntoView()
        }, 10)
    }

    loadPartTypeNameList()
    loadMakeListDistinct()
    loadCarlineList(make)
    loadApplicationList(year, carline, partTypeSlug)

    return (
        <>
            <form onSubmit={handleSubmit}>
                <FormSelect
                    idPrefix={idPrefix}
                    name='partType'
                    title='Part'
                    placeholder='Select Part...'
                    value={partTypeSlug}
                    options={partTypeOptions}
                    noOptionsMessage='No part types found'
                    onChange={handleParam(setPartTypeSlug)}
                    errors={get('partType', [], paramErrors)}
                    maxMenuHeight={maxOptionsHeight}
                />

                <FormSelect
                    idPrefix={idPrefix}
                    name='year'
                    title='Year'
                    placeholder='Select Year...'
                    value={year}
                    options={yearOptions}
                    noOptionsMessage='No years found'
                    onChange={handleParam(setYear)}
                    errors={get('year', [], paramErrors)}
                    maxMenuHeight={maxOptionsHeight}
                />

                <FormSelect
                    idPrefix={idPrefix}
                    name='make'
                    title='Make'
                    placeholder='Select Make...'
                    value={make}
                    options={makeOptions}
                    noOptionsMessage='No makes found'
                    onChange={handleParam(setMake)}
                    errors={get('make', [], paramErrors)}
                    maxMenuHeight={maxOptionsHeight}
                    onMenuOpen={handleMenuOpen}
                />

                <FormSelect
                    idPrefix={idPrefix}
                    name='carline'
                    title='Model'
                    placeholder='Select Model...'
                    value={carline}
                    options={carlineOptions}
                    noOptionsMessage='No models found'
                    onChange={handleCarlineChange}
                    errors={get('carline', [], paramErrors)}
                    maxMenuHeight={maxOptionsHeight}
                    onMenuOpen={handleMenuOpen}
                />

                <CSSTransition
                    in={haveApplications}
                    unmountOnExit
                    classNames='application-transition'
                    timeout={300}
                >
                    <div className='applications' aria-hidden={haveApplications ? 'false' : 'true'}>
                        <FormSelect
                            idPrefix={idPrefix}
                            name='application'
                            title='Options'
                            placeholder='Select Option...'
                            value={application}
                            options={applicationOptions}
                            noOptionsPlaceholder='NO OPTIONS'
                            noOptionsMessage='No options'
                            onChange={handleApplicationChange}
                            errors={get('application', [], paramErrors)}
                        />
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={haveCategoryCodes}
                    unmountOnExit
                    classNames='application-transition'
                    timeout={300}
                >
                    <div className='applications' aria-hidden={haveCategoryCodes ? 'false' : 'true'}>
                        <CategoryCodeSelect
                            idPrefix={idPrefix}
                            name='categoryCode'
                            title='Category'
                            partTypeSlug={partTypeSlug}
                            value={categoryCode}
                            onChange={handleCategoryCodeChange}
                            errors={get('categoryCode', [], paramErrors)}
                        />
                    </div>
                </CSSTransition>

                <div ref={bottomRef} />
                <div className='actions'>
                    <button className='btn btn--yellow' type='submit' disabled={disabled}>{buttonTitle}</button>
                </div>
            </form>
            <style jsx>
                {`
                    @import '@css/variables.css';

                    form {
                        :global(label) {
                            color: white;
                            text-transform: uppercase;
                            font-weight: $weight--bold;
                        }

                        :global(.field-input) {
                            padding-top: 3px;
                        }
                    }

                    .applications {
                        transition: all 300ms ease;

                        &.application-transition-enter {
                            margin: 0;
                            opacity: 0;
                            max-height: 0;
                        }

                        &.application-transition-enter-done,
                        &.application-transition-enter-active {
                            opacity: 1;
                            margin-top: 15px;
                            max-height: 300px;
                        }

                        &.application-transition-exit {
                            opacity: 0;
                            margin: 0;
                            max-height: 0;
                        }
                    }
                `}
            </style>
        </>
    )
})
